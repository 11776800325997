import { Header } from "./components/Header";

const App = () => {
  return (
    <div >
      <Header />
      <div className="my-[5vh] flex flex-row justify-center">
        <img src="./assets/images/landing.jpg" alt="landing page" height={"30%"} width={"90%"} />
      </div>
      <div className="text-white absolute top-[50vh] left-[35vh] text-center">
        <p className="text-[4vw] pb-[30px]">For software solutions that scale</p>
        <button className="text-3xl border-solid border-black border-2 p-3 text-black bg-white hover:bg-black hover:text-white">
          <a href="mailto: info.rudala@gmail.com">Contact Us</a>
        </button>
      </div>
    </div>
  );
}

export default App;
