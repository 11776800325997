export const Header = () => {
  return (
    <header>
      <div className="flex flex-row justify-between mx-[10vw] my-[2vh] items-end">
        <span className="text-3xl">RUDALA</span>
        <span>ABOUT</span>
        <span>WORK</span>
        <span>TEAM</span>
        <span>BLOG</span>
        <span className="text-xl border-solid border-black border-2 p-3 text-white bg-black hover:bg-white hover:text-black"><a href = "mailto: info.rudala@gmail.com">CONTACT US</a></span>
      </div>
    </header>
  );
};
